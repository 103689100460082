<template>
    <div>
        <Navbar page="Relatórios Diagnóstico" link="/entregaveis" nameLink="Entregáveis" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">

            <h1 class="text-3xl mb-5">Relatórios Diagnóstico</h1>

            <div class="grid grid-cols-12 md:gap-4">
                <div class="col-span-3 xl:col-span-3">
                    <div class="h-12 w-full bg-gray-300 flex flex-col items-center justify-center py-4 text-lg border-2 border-gray-300 bg-white">
                        <h3 class="text-xs md:text-base">Entregável</h3>
                    </div>
                </div>
                <div class="col-span-2 xl:col-span-2">
                    <div class="h-12 w-full bg-gray-300 flex flex-col items-center justify-center py-4 text-lg border-2 border-gray-300 bg-white">
                        <h3 class="text-xs md:text-base">Questões</h3>
                    </div>
                </div>
                <div class="col-span-3 xl:col-span-3">
                    <div class="h-12 w-full bg-gray-300 flex flex-col items-center justify-center py-4 text-lg border-2 border-gray-300 bg-white">
                        <h3 class="text-xs md:text-base">Gerado em</h3>
                    </div>
                </div>
                <div class="col-span-4 xl:col-span-4">
                    <div class="h-12 w-full bg-gray-300 flex flex-col items-center justify-center py-4 text-lg border-2 border-gray-300 bg-white">
                        <h3 class="text-xs md:text-base">Opções</h3>
                    </div>
                </div>
            </div>
            <div v-for="item in list" :key="item._id" class="grid grid-cols-12 md:gap-4 md:mt-3">
                <div class="col-span-3 xl:col-span-3">
                    <div class="h-12 w-full bg-yellow-100 flex flex-col items-center justify-center py-4 text-lg border-2 border-yellow-100 bg-white">
                        <h3 class="text-xs md:text-base">Conformidade {{ item ? item.tema : '' }}</h3>
                    </div>
                </div>
                <div class="col-span-2 xl:col-span-2">
                    <div class="h-12 w-full bg-yellow-100 flex flex-col items-center justify-center py-4 text-lg border-2 border-yellow-100 bg-white">
                        <h3 class="text-xs md:text-base">{{ item.perguntas ? item.perguntas : '' }}</h3>
                    </div>
                </div>
                <div class="col-span-3 xl:col-span-3">
                    <div class="h-12 w-full bg-yellow-100 flex flex-col items-center justify-center py-4 text-lg border-2 border-yellow-100 bg-white">
                        <h3 v-if="item && item.dataFinalizada" class="text-xs md:text-base">{{ item.dataFinalizada | moment("DD/MM/YYYY HH:mm")  }}</h3>
                        <h3 v-else class="text-xs md:text-base"> Ainda não finalizada</h3>
                    </div>
                </div>
                <div class="col-span-4 xl:col-span-4">
                    <div class="md:h-12 w-full bg-yellow-100 flex flex-col md:flex-row items-center justify-center py-4 text-lg border-2 border-yellow-100 bg-white">
                        <a target="_blank" rel="noreferrer" :href="`${url_api}/v1/entregaveis/diagnosticoDocx/${item._id}?token=${$store.state.token}`" class="my-1 text-white text-sm_2 hover:bg-blue-600 bg-blue-500 rounded-xl py-1 px-2 md:px-6 mx-1">
                            Visualizar DOCX
                        </a>
                    </div>
                </div>
            </div>
            <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      route: 'diagnosticos',
      list: [],
      page: 1,
      perPage: 20,
      total: 0,
    }
  },
  methods: {
    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { limit: this.perPage , finalizado: true });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async setPage(page) {
      this.page = page;
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      const listReq = await this.$http.post(`/v1/${this.route}/list`, { busca: this.busca, limit, skip, setor: this.setor, finalizado: true });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    }
  },
  async beforeMount() {
    this.start();
  },
}
</script>